import { RouteRecordRaw } from "vue-router";

export const BannersAndLinks: Array<RouteRecordRaw> = [
  {
    path: "/banners",
    name: "banners-view",
    component: () => import("@/views/banners-and-links/BannersAndLinksView.vue"),
    meta: { resources: ["Banner"] },
    children: [
      {
        path: "",
        name: "banners-index",
        component: () => import("@/views/banners-and-links/BannersAndLinksIndex.vue"),
        meta: { resources: ["Banner"] },
      },
      {
        path: "create",
        name: "banners-create",
        component: () => import("@/views/banners-and-links/BannersAndLinksCreate.vue"),
        meta: { resources: ["Banner"] },
      },
      {
        path: "edit/:id",
        name: "banners-edit",
        component: () => import("@/views/banners-and-links/BannersAndLinksEdit.vue"),
        meta: { resources: ["Coupon"] },
      },
    ],
  },
];
export default BannersAndLinks;
