enum Actions {
  // template action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGIN_AS = "auth/loginAs",
  LOGOUT = "auth/signOut",
  REGISTER = "register",
  UPDATE_USER = "auth/updateUser",
  FORGOT_PASSWORD = "auth/forgotPassword",
  RESET_PASSWORD = "auth/resetPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  //own action types
  ENUMS_INIT_ENUM = "enums/initializeEnum",
  I18N_INIT_I18N = "i18n/initializeI18n",
  NOTIFY_ADD_NOTIFICATION = "notifications/addNotification",
  ADD_NEW_AUTOMATION = "automation/addNewAutomation",
  ADD_AUTOMATION_CONDITION = "automation/addNewCondition",
  ADD_AUTOMATION_RULE = "automation/addNewRule",
  UPDATE_AUTOMATION_RULE = "automation/updateRule",
  GET_AUTOMATION_CONDITION = "automation/getCondition",
  UPDATE_AUTOMATION_CONDITION = "automation/updateCondition",
  DELETE_AUTOMATION_CONDITION = "automation/deleteCondition",
  DELETE_AUTOMATION_RULE = "automation/deleteRule",
  ADD_ACTION = "automation/addNewAction",
  //own action types
  I18N_RELOAD_BOOLEAN = "i18n/reloadLocale",
  SET_RESIZED_COLUMN = "datatable/setResizedColumn",
  IS_ACTIVE_TASK = "task/isTaskActive",
  DELETE_ACTIVE_TASK = "task/deleteActiveTask",
  DELETE_PROGRESS = "deleteProgress",
  PING_BACKGROUND_TASK = "task/pingBackgroundTask",
}

enum Mutations {
  // mutation types
  SET_AUTOMATION_ATTRIBUTE_WITH_OPERATOR = "automation/addAttributeValuesWithOperators",
  UPDATE_AUTOMATION = "automation/updateAutomation",
  DELETE_AUTOMATION = "automation/clearAutomation",
  UPDATE_AUTOMATION_CONDITION_VALUE = "automation/updateValueCondition",
  //DELETE_AUTOMATION_ITEM_VALUE = "automation/deleteValueRule",
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  ADD_INDEXES = "automation/addIndexes",
  SET_CONDITION = "automation/setCondition",
  SET_ACTION = "automation/setAction",
  ADD_ACTION_COMPONENT_ITEMS = "automation/addActionComponentItems",
  REMOVE_ACTION_COMPONENT_ITEMS = "automation/removeActionComponentItems",
  DELETE_ACTION_COMPONENT_ITEMS = "automation/deleteActionComponentItems",
  SET_TAB_RULES = "automation/setTabRules",
  SET_OPTION = "automation/setOptions",
  SET_PROGRESS_BAR = "changeProgress",
  SET_TASK = "task/setBackgroundTask",
  UPDATE_TASK_BY_RESOURCE = "task/updateTaskByResource",
}

export { Actions, Mutations };
